export default [
  {
    header: 'Homepage',
    title: 'Homepage',
    route: 'home',
    icon: '',
  },
  {
    header: 'Peoples',
    title: 'peoples',
    icon: '',
    children: [
      {
        title: 'Admins',
        route: 'admins',
        icon: 'UserIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'UsersIcon',
      }
    ],
  },
  {
    header: 'Tracks',
    title: 'Tracks',
    icon: '',
    route: 'tracks',
  },
  {
    header: 'Billboards',
    title: 'Billboards',
    route: 'billboards',
    icon: '',
  },
  {
    header: 'Cars',
    title: 'Cars',
    route: 'cars',
    icon: '',
  },
  {
    header: 'Marketplace',
    title: 'Marketplace',
    icon: '',
    children: [
      {
        title: 'Showroom',
        route: 'showroom',
        icon: 'ArchiveIcon',
      },
      {
        title: 'Live Marketplace',
        route: 'liveMarketplace',
        icon: 'ClipboardIcon',
      },
    ],
  },
  {
    header: 'Races',
    title: 'Races',
    icon: '',
    route: 'races',
  },
  {
    header: 'Tournaments',
    title: 'Tournaments',
    route: 'tournaments',
    icon: '',
  },
  {
    header: 'Notices',
    title: 'Notices',
    route: 'notices',
    icon: '',
  },
  {
    header: 'Version',
    title: 'Version',
    route: 'version',
    icon: '',
  },
  {
    header: 'Pay Request',
    title: 'Pay Request',
    route: 'payrequest',
    icon: '',
  },
]
